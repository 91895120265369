import { useState, useEffect } from "react";
import { fromArrayBuffer } from "geotiff";
import SiteService from "../../services/Site";
import Api from "../../services/Api";
import to from "await-to-js";
import dayjs from "dayjs";

// Convertit une image TIFF en objet avec les dimensions et les données raster
// Utilisé pour le traitement des données solaires
const processTiffImage = async (buffer) => {
  const tiff = await fromArrayBuffer(buffer);
  const image = await tiff.getImage();
  const width = image.fileDirectory.ImageWidth;
  const height = image.fileDirectory.ImageLength;
  const rasters = await image.readRasters();

  return { width, height, rasters };
};

// Convertit un Buffer Node.js en ArrayBuffer compatible avec la lib GeoTIFF
const toArrayBuffer = (buffer) => {
  const arrayBuffer = new ArrayBuffer(buffer.length);
  new Uint8Array(arrayBuffer).set(buffer);
  return arrayBuffer;
};

// Fonction permettant de récupérer les fichiers stockés dans le back contenant les buffers
const fetchAndProcessTiffData = async (siteId) => {
  try {
    const response = await Api.getSolarFiles(siteId);
    const data = response.data;

    const [maskImageData, tiffImageData] = await Promise.all([
      processTiffImage(toArrayBuffer(data.maskData.data)),
      processTiffImage(toArrayBuffer(data.annualFluxData.data)),
    ]);

    return { maskImageData, tiffImageData };
  } catch (error) {
    console.error(
      "Erreur lors du traitement ou de l'envoi des images :",
      error,
    );
    throw error;
  }
};

// Hook personnalisé pour gérer les données solaires d'un scénario
// Gère le chargement des images TIFF et les données du bâtiment
// Initialise les données si elles n'existent pas encore pour le projet

export default function useSolarData(
  lat,
  lon,
  site,
  scenario,
  isSolarError,
  isSolarLoading,
  hasCadastre,
) {
  const [tiffData, setTiffData] = useState(null);
  const [maskData, setMaskData] = useState(null);

  useEffect(() => {
    if (!lat || !lon || !hasCadastre) return;

    const fetchSolarData = async () => {
      isSolarError(null);
      isSolarLoading(true);

      try {
        const siteId = site.id;

        // Initialise les données solaires si nécessaire (solarData !== true)
        // puis charge les images TIFF et les données du bâtiment
        if (site.solarData !== true) {
          try {
            const solarLayers = await Api.initLayers(lat, lon, siteId);

            if (solarLayers.status === 200) {
              await to(SiteService.updateKey(siteId, "solarData", true));
            }
          } catch (error) {
            isSolarError(
              error.message ||
                "Erreur lors de l'initialisation des données solaires",
            );
            isSolarLoading(false);
            return;
          }
        }

        const today = dayjs();
        const dateOfLastModif = dayjs(scenario._lastModified);

        const isOlderThanOneMonth = today.diff(dateOfLastModif, "month") >= 1;

        if (isOlderThanOneMonth) {
          try {
            const solarLayers = await Api.initLayers(lat, lon, siteId);

            if (solarLayers.status === 200) {
              await to(SiteService.updateKey(siteId, "solarData", true));
            } else {
              throw new Error(
                "Erreur lors de la récupération des données solaires",
              );
            }
          } catch (error) {
            isSolarError(
              error.message ||
                "Erreur lors de la mise à jour des données solaires",
            );
            isSolarLoading(false);
            return;
          }
        }

        const { maskImageData, tiffImageData } = await fetchAndProcessTiffData(
          siteId,
        );

        setMaskData(maskImageData);
        setTiffData(tiffImageData);
        isSolarLoading(false);
      } catch (error) {
        isSolarError(error.message || "Une erreur inconnue est survenue");
      }
    };

    fetchSolarData();
  }, [hasCadastre]);

  return { tiffData, maskData };
}
