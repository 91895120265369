const renderRGB = (rgb, mask, roofOnly) => {
  if (!rgb || !rgb.width || !rgb.height) {
    console.error("⚠️ Erreur: Données RGB invalides", rgb);
    return null;
  }
  if (roofOnly && (!mask || !mask.width || !mask.height)) {
    console.error("⚠️ Erreur: Données mask invalides en roofOnly", mask);
    return null;
  }

  const canvas = document.createElement("canvas");
  canvas.width = mask ? mask.width : rgb.width;
  canvas.height = mask ? mask.height : rgb.height;

  const dw = rgb.width / canvas.width;
  const dh = rgb.height / canvas.height;

  const ctx = canvas.getContext("2d");
  const img = ctx.createImageData(canvas.width, canvas.height);

  for (let y = 0; y < canvas.height; y++) {
    for (let x = 0; x < canvas.width; x++) {
      const rgbIdx = Math.floor(y * dh) * rgb.width + Math.floor(x * dw);
      const maskIdx = y * canvas.width + x;
      const imgIdx = y * canvas.width * 4 + x * 4;

      img.data[imgIdx + 0] = rgb.rasters[0][rgbIdx];
      img.data[imgIdx + 1] = rgb.rasters[1][rgbIdx];
      img.data[imgIdx + 2] = rgb.rasters[2][rgbIdx];
      img.data[imgIdx + 3] = roofOnly
        ? mask.rasters[0][maskIdx] > 0
          ? 255
          : 0
        : 255;
    }
  }

  ctx.putImageData(img, 0, 0);
  return canvas;
};

const renderPalette = ({ data, mask, colors, min, max, index, roofOnly }) => {
  const palette = createPalette(colors ?? ["000000", "ffffff"]);
  const indices = data.rasters[index ?? 0]
    .map((x) => normalize(x, max ?? 1, min ?? 0))
    .map((x) => Math.round(x * (palette.length - 1)));

  return renderRGB(
    {
      ...data,
      rasters: [
        indices.map((i) => palette[i].r),
        indices.map((i) => palette[i].g),
        indices.map((i) => palette[i].b),
      ],
    },
    roofOnly ? mask : null,
    roofOnly,
  );
};

const createPalette = (hexColors) => {
  const rgb = hexColors.map(colorToRGB);
  const size = 256;
  const step = (rgb.length - 1) / (size - 1);
  return Array(size)
    .fill(0)
    .map((_, i) => {
      const index = i * step;
      const lower = Math.floor(index);
      const upper = Math.ceil(index);
      return {
        r: lerp(rgb[lower].r, rgb[upper].r, index - lower),
        g: lerp(rgb[lower].g, rgb[upper].g, index - lower),
        b: lerp(rgb[lower].b, rgb[upper].b, index - lower),
      };
    });
};

const colorToRGB = (color) => {
  const hex = color.startsWith("#") ? color.slice(1) : color;
  return {
    r: parseInt(hex.substring(0, 2), 16),
    g: parseInt(hex.substring(2, 4), 16),
    b: parseInt(hex.substring(4, 6), 16),
  };
};

const normalize = (x, max = 1, min = 0) => {
  const y = (x - min) / (max - min);
  return clamp(y, 0, 1);
};

const lerp = (x, y, t) => {
  return x + t * (y - x);
};

const clamp = (x, min, max) => {
  return Math.min(Math.max(x, min), max);
};

export const rgbToColor = ({ r, g, b }) => {
  const f = (x) => {
    const hex = Math.round(x).toString(16);
    return hex.length == 1 ? `0${hex}` : hex;
  };
  return `#${f(r)}${f(g)}${f(b)}`;
};

export const generateOverlay = (tiffData, maskData, roofOnly) => {
  if (!tiffData) return null;

  const colors = [
    "000000", // Noir pour les zones non exploitables
    "333333", // Gris foncé
    "666666", // Gris moyen
    "FFD700", // Jaune doré
    "FFA500", // Orange
    "FF4500", // Orange-rouge
    "FF0000", // Rouge vif
  ];

  const rasterArray = tiffData.rasters[0];

  const min = Math.min(...rasterArray);
  const max = Math.max(...rasterArray);

  const overlayCanvas = renderPalette({
    data: tiffData,
    mask: roofOnly ? maskData : null,
    colors,
    min,
    max,
    index: 0,
    roofOnly,
  });

  return overlayCanvas;
};

export class SolarOverlay extends google.maps.OverlayView {
  constructor(latitude, longitude, roofOnly, image, radiusMeters = 50) {
    super();
    this.latitude = latitude;
    this.longitude = longitude;
    this.roofOnly = roofOnly;
    this.image = image;
    this.radiusMeters = radiusMeters;
  }

  onAdd() {
    this.div = document.createElement("div");
    this.div.style.borderStyle = "none";
    this.div.style.position = "absolute";

    const canvas = document.createElement("canvas");
    canvas.style.width = "100%";
    canvas.style.height = "100%";
    canvas.style.position = "absolute";
    canvas.style.opacity = "1";

    this.canvas = canvas;
    this.div.appendChild(this.canvas);

    const panes = this.getPanes();
    panes.overlayLayer.appendChild(this.div);
  }

  draw() {
    if (!this.image || !(this.image instanceof HTMLCanvasElement)) {
      console.error("Image invalide dans SolarOverlay");
      return;
    }

    let bounds;

    const center = new google.maps.LatLng(this.latitude, this.longitude);

    const squareRadius = this.radiusMeters * Math.sqrt(2);

    const sw = google.maps.geometry.spherical.computeOffset(
      center,
      squareRadius,
      225,
    );

    const ne = google.maps.geometry.spherical.computeOffset(
      center,
      squareRadius,
      45,
    );

    bounds = new google.maps.LatLngBounds(sw, ne);

    const overlayProjection = this.getProjection();

    const swPx = overlayProjection.fromLatLngToDivPixel(bounds.getSouthWest());
    const nePx = overlayProjection.fromLatLngToDivPixel(bounds.getNorthEast());

    const width = Math.abs(nePx.x - swPx.x);
    const height = Math.abs(swPx.y - nePx.y);

    this.div.style.left = `${swPx.x}px`;
    this.div.style.top = `${nePx.y}px`;
    this.div.style.width = `${width}px`;
    this.div.style.height = `${height}px`;

    this.canvas.width = width;
    this.canvas.height = height;

    const ctx = this.canvas.getContext("2d");
    ctx.drawImage(this.image, 0, 0, this.canvas.width, this.canvas.height);
  }

  onRemove() {
    if (this.div) {
      this.div.parentNode.removeChild(this.div);
      this.div = null;
    }
  }
}
